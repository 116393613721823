import { Box, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../components/customTheme";
import { useEffect, useState } from "react";
import PasswordInputField from "../components/Login/PasswordInputField";
import LoginPageBtn from "../components/Login/LoginPageBtn";
import LoginPageLogo from "../components/Login/LoginPageLogo";
import "../css/globalVariables.css";
import {
  activateTrainer,
  changeTrainerPassword,
  checkTrainerPassword,
  verifyTrainerTokens,
} from "../api/auth-api";
import LoginPageTypography from "../components/Login/LoginPageTypography";
import { checkIfPasswordMatchesRegex } from "../util/regex-functions";
import { useHistory, useLocation } from "react-router-dom";
import { allRoutes } from "../routes/all-routes";
import { localStorageKeys } from "../constants/storage-keys";

interface LocationState {
  trainerUUID: string;
  trainerAccessToken: string;
}

const ChangePasswordPage: React.FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const [trainerUUID, setTrainerUUID] = useState<string>();
  const [trainerToken, setTrainerToken] = useState<string>();

  const setLocalStorage = (trainerUUID: string, trainerAccessToken: string) => {
    localStorage.setItem(localStorageKeys.trainerUUID, trainerUUID);
    localStorage.setItem(
      localStorageKeys.trainerAccessToken,
      trainerAccessToken
    );
  };

  useEffect(() => {
    if (location.state) {
      const {
        trainerUUID: stateTrainerUUID,
        trainerAccessToken: stateTrainerToken,
      } = location.state;
      setTrainerUUID(stateTrainerUUID);
      setTrainerToken(stateTrainerToken);
      setLocalStorage(stateTrainerUUID, stateTrainerToken);
    } else if (location.search) {
      const params = new URLSearchParams(location.search);
      const searchTrainerUUID = params.get("trainerUUID");
      const searchTrainerToken = params.get("trainerAccessToken");
      setTrainerUUID(searchTrainerUUID);
      setTrainerToken(searchTrainerToken);
      setLocalStorage(searchTrainerUUID, searchTrainerToken);
    } else {
      history.push(allRoutes.default);
    }
  }, [history, location.state, location.search]);

  useEffect(() => {
    if (trainerToken && trainerUUID) {
      const checkTokenValidity = async () => {
        const { accessTokenValid } = await verifyTrainerTokens(trainerToken);
        if (!accessTokenValid || !trainerUUID) {
          history.push(allRoutes.default);
        }
      };

      checkTokenValidity();
    }
  }, [trainerToken, trainerUUID, history]);

  const textFieldOptions = {
    default:
      "Gib hier bitte das alte Passwort und dein neues Passwort ein, bestätige es durch wiederholte Eingabe.",
    errorOldPassword: "Das alte Passwort ist falsch.",
    errorNewPassword:
      "Die Eingabefelder des neuen Passwörter stimmen nicht überein oder ist nicht lang genug. Bitte beachte das dein Passwort mindestens 8 Zeichen beinhalten muss, davon ein Groß und Klein Buchstabe mindestens eine Zahl und ein Sonderzeichen [!@#$%^&*()_-+=?].",
    errorServer:
      "Es gab ein Problem mit dem Server. Bitte versuche es noch einmal.",
    success:
      "Dein Passwort wurde erfolgreich geändert! Bitte melde dich mit deinem neuen Passwort an.",
  };

  const [textFieldValue, setTextFieldValue] = useState(
    textFieldOptions.default
  );
  const [oldPasswordValue, setOldPasswordValue] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [allValid, setAllValid] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    if (
      oldPasswordValue.length > 0 &&
      newPasswordValue.length > 0 &&
      confirmPasswordValue.length > 0
    )
      setAllValid(true);
    else {
      setAllValid(false);
    }
  }, [oldPasswordValue, newPasswordValue, confirmPasswordValue]);
  /* eslint-enable */

  /** Checks if the given new passwords match and if the confirm to our password rules */
  const checkPasswordMatchAndRegex = (
    newPassword: string,
    confirmPassword: string
  ): boolean => {
    return (
      newPassword === confirmPassword &&
      checkIfPasswordMatchesRegex(newPassword)
    );
  };

  const changePasswordBtnHandler = async () => {
    const checkOldPassword: boolean = await checkTrainerPassword(
      trainerUUID,
      oldPasswordValue
    );
    const checkNewPassword: boolean = checkPasswordMatchAndRegex(
      newPasswordValue,
      confirmPasswordValue
    );
    if (checkOldPassword && checkNewPassword) {
      try {
        await changeTrainerPassword(trainerUUID, newPasswordValue);
        await activateTrainer(trainerUUID);
      } catch (error) {
        setTextFieldValue(
          `${textFieldOptions.errorServer} ${error.toString()}`
        );
        setError(true);
      }
      setSuccess(true);
    } else if (!checkOldPassword) {
      setTextFieldValue(textFieldOptions.errorOldPassword);
      setError(true);
    } else if (!checkNewPassword) {
      setTextFieldValue(textFieldOptions.errorNewPassword);
      setError(true);
    }
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          <Box sx={{ margin: "15px" }}>
            <LoginPageLogo></LoginPageLogo>
          </Box>
          <Box
            sx={{
              margin: "0 auto 0 auto",
              padding: "20px",
              maxWidth: "400px",
            }}
          >
            {!success && (
              <>
                <LoginPageTypography
                  typographyColor={error ? "var(--red)" : "var(--grey)"}
                >
                  {textFieldValue}
                </LoginPageTypography>

                <PasswordInputField
                  textFieldLabel={"Altes Passwort"}
                  textFieldValue={oldPasswordValue}
                  textFieldError={error}
                  textFieldChangeHandler={(event) =>
                    setOldPasswordValue(event.target.value)
                  }
                ></PasswordInputField>

                <Box
                  sx={{
                    padding: "10px",
                  }}
                ></Box>

                <PasswordInputField
                  textFieldLabel={"Neues Passwort"}
                  textFieldValue={newPasswordValue}
                  textFieldError={error}
                  textFieldChangeHandler={(event) =>
                    setNewPasswordValue(event.target.value)
                  }
                ></PasswordInputField>

                <PasswordInputField
                  textFieldLabel={"Passwort bestätigen"}
                  textFieldValue={confirmPasswordValue}
                  textFieldError={error}
                  textFieldChangeHandler={(event) =>
                    setConfirmPasswordValue(event.target.value)
                  }
                ></PasswordInputField>

                <LoginPageBtn
                  text={"Passwort ändern"}
                  isValid={allValid}
                  onClickFunction={() => {
                    changePasswordBtnHandler();
                  }}
                ></LoginPageBtn>
              </>
            )}
            {success && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0 auto 0 auto",
                  padding: "20px",
                  maxWidth: "400px",
                }}
              >
                <Typography
                  align="center"
                  sx={{
                    color: "var(--grey)",
                    margin: "0 auto 0 auto",
                    fontSize: "var(--h3)",
                  }}
                >
                  {textFieldOptions.success}
                </Typography>
                <LoginPageBtn
                  text={"Weiterleitung"}
                  isValid={true}
                  onClickFunction={() => {
                    history.push(allRoutes.default);
                  }}
                ></LoginPageBtn>
              </Box>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ChangePasswordPage;
