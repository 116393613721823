import { Box, Grid, TextField, ThemeProvider, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoginPageBtn from "../components/Login/LoginPageBtn";
import LoginPageLogo from "../components/Login/LoginPageLogo";
import PasswordInputField from "../components/Login/PasswordInputField";
import "../css/globalVariables.css";
import { loginUser } from "../api/auth-api";
import { customTheme } from "../components/customTheme";
import { SnackBarContext } from "../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";
import { HttpStatusCode } from "axios";
import { allRoutes } from "../routes/all-routes";
import { useHistory } from "react-router-dom";

const LoginPage: React.FC = () => {
  const history = useHistory();
  const snackBarCtx = useContext(SnackBarContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const [loginValid, setLoginValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLoginValid(email.length !== 0 && password.length !== 0);
  }, [email, password]);

  const handleLoginBtn = async () => {
    setIsLoading(true);
    await loginUser(email, password)
      .then((response) => {
        if (response.status === HttpStatusCode.Created) {
          const params = new URLSearchParams({
            adminAccessToken: response.admin_access_token,
            trainerAccessToken: response.trainer_access_token,
            trainerUUID: response.uuid,
            isAdmin: response.is_admin.toString(),
            isActivated: response.is_activated.toString(),
          });

          if (response.is_activated === false) {
            history.push(allRoutes.changePassword, {
              trainerUUID: response.uuid,
              trainerAccessToken: response.trainer_access_token,
            });
          }

          if (response.is_admin) {
            window.location.href = `${
              process.env.REACT_APP_ADMIN_APP_URL
            }/auth?${params.toString()}`;
          } else {
            window.location.href = `${
              process.env.REACT_APP_TRAINER_APP_URL
            }/auth?${params.toString()}`;
          }
        } else {
          snackBarCtx.openSnackbar(
            `${response.status} | Ein Fehler ist aufgetreten. Bitte überprüfe deine Anmeldedaten und versuche es erneut...`
          );
        }
      })
      .catch((error) => {
        setError(true);
        if (error.response.status === 401) {
          snackBarCtx.openSnackbar(
            "Falsche Anmeldedaten. Bitte überprüfe deinen Benutzernamen und dein Passwort und versuche erneut."
          );
        } else {
          snackBarCtx.openSnackbar(
            `Ein Fehler ist aufgetreten | Fehlermeldung : ${error.message}`
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            <ThemeProvider theme={customTheme}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minHeight: "95vh",
                }}
              >
                <LoginPageLogo />
                <Box
                  sx={{
                    margin: "0 auto 40px auto",
                    padding: "20px",
                    maxWidth: "400px",
                  }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
                        {
                          WebkitBoxShadow: "0 0 0 30px white inset !important",
                        },
                    }}
                    label={"E-Mail"}
                    variant="outlined"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    error={error}
                  />

                  <PasswordInputField
                    textFieldLabel={"Passwort"}
                    textFieldValue={password}
                    textFieldError={error}
                    textFieldChangeHandler={(event) => {
                      setPassword(event.target.value);
                    }}
                  ></PasswordInputField>

                  <Grid container justifyContent="flex-end">
                    <button
                      style={{
                        width: "40%",
                        marginTop: "20px",
                        whiteSpace: "nowrap",
                        boxShadow: "none",
                        border: "none",
                        borderRadius: "var(--borderRadiusLarge)",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => {
                        history.push(allRoutes.forgotPassword);
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "var(--p2)",
                          fontWeight: "bold",
                          color: "var(--orange)",
                        }}
                      >
                        {"Passwort vergessen?"}
                      </Typography>
                    </button>
                  </Grid>

                  <LoginPageBtn
                    text={"Anmelden"}
                    isValid={loginValid}
                    onClickFunction={() => {
                      handleLoginBtn();
                    }}
                  ></LoginPageBtn>
                </Box>
              </Box>
            </ThemeProvider>
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};
export default LoginPage;
