import { Box } from "@mui/material";
import logo from "../../img/kindersportlogo.jpg";

const LoginPageLogo: React.FC = () => {
  return (
    <>
      <Box
        component="img"
        sx={{
          width: "90%",
          margin: "50px auto 10px auto",
          maxHeight: "245px",
          maxWidth: "420px",
        }}
        alt="Kindersportlogo"
        src={logo}
      />
    </>
  );
};

export default LoginPageLogo;
