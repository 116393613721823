import axios from "axios";
import { getAuthAxiosInstance, AUTH_API_URL } from "./api-config";
import { localStorageKeys } from "../constants/storage-keys";

export async function verifyTrainerTokens(accessToken: string) {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(
    `${AUTH_API_URL}/auth/verify-trainer`,
    { accessToken },
    {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": AUTH_API_URL,
        "Access-Control-Allow-Credentials": true,
      },
    }
  );
  return response.data;
}

export async function refreshTrainerToken() {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.get(`/auth/refresh-trainer`, {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": AUTH_API_URL,
      "Access-Control-Allow-Credentials": true,
    },
  });
  const accessToken = response.data.accessToken;
  localStorage.setItem(localStorageKeys.trainerAccessToken, accessToken);
  return response;
}

export async function refreshAdminToken() {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.get(`/auth/refresh-admin`, {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": AUTH_API_URL,
      "Access-Control-Allow-Credentials": true,
    },
  });
  const accessToken = response.data.accessToken;
  localStorage.setItem(localStorageKeys.adminAccessToken, accessToken);
  return response;
}

export async function loginUser(
  email: string,
  password: string
): Promise<{
  status: number;
  uuid: string;
  admin_access_token: any;
  trainer_access_token: string;
  is_admin: boolean;
  is_activated: boolean;
}> {
  const response = await axios.post(
    `${AUTH_API_URL}/auth/login`,
    {
      email: email,
      password: password,
    },
    {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": AUTH_API_URL,
        "Access-Control-Allow-Credentials": true,
      },
    }
  );
  return {
    status: response.status,
    ...response.data,
  };
}

export async function logoutUser() {
  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);
  const data = {
    trainerUUID: trainerUUID,
  };
  localStorage.clear();

  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(
    `/auth/logout`,
    {
      data: data,
    },
    {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": AUTH_API_URL,
        "Access-Control-Allow-Credentials": true,
      },
    }
  );
  return response;
}

export async function checkTrainerPassword(
  trainerUUID: string,
  password: string
) {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(`/auth/check-password`, {
    trainerUUID: trainerUUID,
    password: password,
  });
  return response.data;
}

export async function changeTrainerPassword(
  trainerUUID: string,
  password: string
) {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(`/auth/change-password`, {
    trainerUUID: trainerUUID,
    password: password,
  });
  return response.data;
}

export async function activateTrainer(trainerUUID: string) {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(
    `/auth/${trainerUUID}/activate-trainer`
  );
  return response.data;
}

export async function resetTrainerPassword(
  trainerEmail: string
): Promise<{ success: boolean; message: string }> {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(`/auth/reset-password`, {
    trainerEmail: trainerEmail,
  });
  return response.data;
}
