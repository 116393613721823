import { Typography } from "@mui/material";
import "../../css/globalVariables.css";

const LoginPageTypography: React.FC<{
  children: React.ReactNode;
  typographyColor?: string;
}> = ({ children, typographyColor }) => {
  return (
    <>
      <Typography
        align="center"
        sx={{
          color: typographyColor ? typographyColor : "var(--gray)",
          marginTop: "20px",
          fontSize: "var(--h3)",
        }}
      >
        {children}
      </Typography>
    </>
  );
};

export default LoginPageTypography;
