import axios from "axios";
import { localStorageKeys } from "../constants/storage-keys";
import { logoutUser, refreshAdminToken } from "../api/auth-api";
import { AUTH_API_URL } from "../config/config";
import Cookies from "js-cookie";
import { ADMIN_REFRESH_COOKIE_KEY } from "../config/cookie-keys";

const axiosAuthInstance = axios.create({
  baseURL: AUTH_API_URL,
});

let isRefreshing = false; // Flag to prevent multiple token refresh attempts
let refreshSubscribers: any[] = []; // Array to hold all the subscribers waiting for the token refresh

function onTokenRefreshed(newToken: string) {
  // Notify all the subscribers that the token has been refreshed and update their requests
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
}

function addSubscriber(callback: any) {
  // Add new subscribers to the refreshSubscribers array
  refreshSubscribers.push(callback);
}

axiosAuthInstance.interceptors.request.use(
  (config) => {
    const requestURL = config.url;

    if (requestURL.includes("/auth/refresh")) {
      const refreshToken = Cookies.get(ADMIN_REFRESH_COOKIE_KEY);
      config.headers["Authorization"] = `Bearer ${refreshToken}`;
    } else {
      const authToken = localStorage.getItem(
        localStorageKeys.trainerAccessToken
      );
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    if (["POST", "PUT", "DELETE"].includes(config.method?.toUpperCase())) {
      const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);
      if (trainerUUID) {
        config.headers["X-Trainer-UUID"] = trainerUUID;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAuthInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.config.url !== "/auth/refresh") {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addSubscriber((newToken: string) => {
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(axios(originalRequest));
          });
        });
      }
      isRefreshing = true;
      originalRequest._retry = true;

      try {
        // Refresh the access token
        const tokensResponse = await refreshAdminToken();
        const accessToken = tokensResponse.data.accessToken;

        onTokenRefreshed(accessToken);

        // // Update the Authorization header with the new access token
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

        // Resend the original request with the updated access token
        return axios(originalRequest);
      } catch (refreshError) {
        await logoutUser();
      } finally {
        isRefreshing = false;
      }
    }

    // If the response status is not 401 or the token refresh attempt fails, return the error
    return Promise.reject(error);
  }
);

export default axiosAuthInstance;
