export const passwordRegexPattern =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-=?])[A-Za-z\d!@#$%^&*()_\-=?]{8,16}$/;

export const checkIfPasswordMatchesRegex = (inputPw: string) => {
  return passwordRegexPattern.test(inputPw);
};

export const isEmail = (email: string, isRequired: boolean): boolean => {
  if (email === undefined || email.trim() === "") return !isRequired;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isValidPLZ = (plz: string, isRequired: boolean): boolean => {
  if (plz === undefined || plz.trim() === "") return !isRequired;
  const regex = /^[0-9]{5}$/;
  return regex.test(plz);
};

export const isNotEmpty = (
  input: string | boolean | undefined,
  isRequired: boolean
): boolean => {
  if (input === undefined) return !isRequired;

  if (typeof input === "string") {
    return input.trim() !== "";
  }
  if (typeof input === "boolean") {
    return true;
  }
  return false;
};

export const isValidTime = (time: string, isRequired: boolean): boolean => {
  if (time === undefined || time.trim() === "") return !isRequired;
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
  return regex.test(time);
};

export const isDigitsOnly = (input: string, isRequired: boolean): boolean => {
  if (input === undefined || input.trim() === "") return !isRequired;
  const regex = /^\d+$/;
  return regex.test(input);
};
