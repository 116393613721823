import LoginPage from "./pages/Login.page";
import ForgotPasswordPage from "./pages/ForgotPassword.page";
import { allRoutes } from "./routes/all-routes";
import { Route, Switch } from "react-router-dom";
import ChangePasswordPage from "./pages/ChangePassword.page";

function App() {
  return (
    <>
      <main>
        <Switch>
          <Route
            component={ForgotPasswordPage}
            path={allRoutes.forgotPassword}
          />
          <Route
            component={ChangePasswordPage}
            path={allRoutes.changePassword}
          />
          <Route exact component={LoginPage} path={allRoutes.default} />
        </Switch>
      </main>
    </>
  );
}

export default App;
