import { Typography } from "@mui/material";
import "../../css/globalVariables.css";

const LoginPageBtn: React.FC<{
  text: string;
  isValid: boolean;
  onClickFunction: () => void;
}> = ({ text, isValid, onClickFunction }) => {
  return (
    <>
      <button
        style={{
          padding: "15px",
          width: "100%",
          boxShadow: "none",
          marginTop: "20px",
          backgroundColor: isValid ? "var(--orange)" : "var(--lightOrange)",
          border: "none",
          borderRadius: "var(--borderRadiusLarge)",
        }}
        onClick={onClickFunction}
      >
        <Typography
          sx={{
            fontSize: "small",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {text}
        </Typography>
      </button>
    </>
  );
};

export default LoginPageBtn;
