import { ThemeProvider } from "@emotion/react";
import { Box, Divider, TextField } from "@mui/material";
import { useState } from "react";
import { customTheme } from "../components/customTheme";
import LoginPageTypography from "../components/Login/LoginPageTypography";
import LoginPageBtn from "../components/Login/LoginPageBtn";
import LoginPageLogo from "../components/Login/LoginPageLogo";
import { resetTrainerPassword } from "../api/auth-api";
import { isEmail } from "../util/regex-functions";
import { allRoutes } from "../routes/all-routes";
import { useHistory } from "react-router-dom";

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();

  const [emailInput, setEmailInput] = useState("");
  const [inputValid, setInputValid] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);
  const [requestSent, setRequestSent] = useState(undefined);
  const [requestMessage, setRequestMessage] = useState("");

  async function handlePasswordReset() {
    const response = await resetTrainerPassword(emailInput);
    setRequestSent(response.success);
    setRequestMessage(response.message);
  }

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          <LoginPageLogo></LoginPageLogo>
          <Box
            sx={{
              margin: "0 auto 0 auto",
              padding: "20px",
              maxWidth: "400px",
            }}
          >
            {!requestSent && (
              <>
                <LoginPageTypography>
                  {
                    "Gib uns deine E-Mail damit wir dir dein neues Passwort schicken können. Du kannst dich dann mit dem neuen Passwort anmelden und es in deinem Profil ändern."
                  }
                </LoginPageTypography>
                <LoginPageTypography typographyColor="var(--red)">
                  {requestMessage}
                </LoginPageTypography>

                <TextField
                  label={"E-Mail"}
                  variant="outlined"
                  value={emailInput}
                  onClick={() => {
                    setInputTouched(true);
                  }}
                  onChange={(event) => {
                    setEmailInput(event.target.value);
                    setInputValid(isEmail(event.target.value, true));
                  }}
                  sx={{ width: "100%", marginTop: "20px" }}
                  error={inputTouched && !inputValid}
                />

                <LoginPageBtn
                  text={"Neues Passwort anfordern"}
                  isValid={inputValid}
                  onClickFunction={() => {
                    if (inputValid) {
                      handlePasswordReset();
                    }
                  }}
                ></LoginPageBtn>
              </>
            )}
            {requestSent && (
              <LoginPageTypography>
                {
                  "Vielen Dank! Wir haben dir soeben eine E-Mail mit deinem neuen Passwort geschickt. Du kannst dich anschließend mit deinem neuen Passwort anmelden und es in deinem Profil wieder ändern."
                }
              </LoginPageTypography>
            )}

            <Divider
              sx={{
                border: "2px solid lightgrey",
                margin: "40px 10px 20px 10px",
              }}
            ></Divider>

            <LoginPageBtn
              text={"Zurück zur Anmeldung"}
              isValid={true}
              onClickFunction={() => {
                history.push(allRoutes.default);
              }}
            ></LoginPageBtn>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ForgotPasswordPage;
