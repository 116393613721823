import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ChangeEventHandler, useState } from "react";

const PasswordInputField: React.FC<{
  textFieldLabel: string;
  textFieldValue: string;
  textFieldError: boolean;
  textFieldChangeHandler: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
}> = ({
  textFieldLabel,
  textFieldValue,
  textFieldError,
  textFieldChangeHandler,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <TextField
        label={textFieldLabel}
        variant="outlined"
        type={showPassword ? "text" : "password"}
        value={textFieldValue}
        onChange={textFieldChangeHandler}
        sx={{
          width: "100%",
          marginTop: "20px",
          "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
            {
              WebkitBoxShadow: "0 0 0 30px white inset !important",
            },
        }}
        error={textFieldError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default PasswordInputField;
